export default function HomePage() {
    return(
    <>
        <section class="banner" id="inicio">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="banner-desc">
                            <h2>Protege a tu familia y a ti mismo. ¡Vacúnate hoy mismo!</h2>
                            <p>Nuestro equipo de profesionales brindan servicios de vacunación confiables y seguros.</p>
                            <div>
                                <a href="#servicios" class="btn-learn-more">Ver servicios</a>
                                <a href="#contacto" class="btn-custom">Contacto</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="banner-image">
                            <img src="images/banner-image.png" alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="our-programm" id="servicios">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-title" style={{marginTop: 30}}>
                            <h2>Nuestros servicios</h2>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                        <div class="programm-single">
                            <div class="icon-box">
                                <i class="flaticon-tool"></i>
                            </div>
                            
                            <h3>Planificación de vacunación a largo plazo</h3>
                            <p>Nuestro equipo puede ayudarte a establecer un calendario de vacunación personalizado, teniendo en cuenta tus necesidades específicas.</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="programm-single">
                            <div class="icon-box">
                                <i class="flaticon-baby"></i>
                            </div>
                            
                            <h3>Vacunación integral</h3>
                            <p>Ofrecemos distintas vacunas para personas de todas las edades, desde recién nacidos hasta adultos mayores.</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="programm-single">
                            <div class="icon-box">
                                <i class="flaticon-care"></i>
                            </div>
                            
                            <h3>Consultas personalizadas</h3>
                            <p>Nuestro equipo está disponible para responder a tus preguntas y brindarte asesoramiento personalizado sobre vacunación.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="aboutus" id="nosotros">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="about-desc">
                            <h3>Nosotros
                            </h3>
                            
                            <p>En Vaccin, nos enorgullece ofrecer servicios de vacunación confiables y de calidad a nuestra comunidad. Con años de experiencia en el campo de la salud, nuestro equipo altamente capacitado se dedica a brindar la mejor atención y servicio a nuestros pacientes.</p>

                            <p>Nuestro objetivo es proporcionar un entorno acogedor y cómodo para nuestros pacientes. Nos esforzamos por brindar una experiencia positiva y sin estrés durante cada visita al vacunatorio.</p>
                        </div>
                        
                    </div>
                    
                    <div class="col-md-6">
                        <div class="about-image">
                            <img src="images/estab.jpeg" alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="contactus" id="contacto">
            <div class="container">
            
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-title">
                            <h2>Contáctanos</h2>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-3">
                        <div class="contact-info-single">
                            <div class="icon-box"><i class="fa fa-phone"></i></div>
                            <h3>Llámanos</h3>
                            <a href="tel:+56943574796">(569) 43 574 796</a>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="contact-info-single">
                            <div class="icon-box"><i class="fa fa-phone"></i></div>
                            <h3>Correo</h3>
                            <a href="mailto:contacto@vaccin.cl">contacto@vaccin.cl</a>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="contact-info-single">
                            <div class="icon-box"><i class="fa fa-map-marker"></i></div>
                            <h3>Ubicación</h3>
                            <a href="https://goo.gl/maps/Le9cmsLncfjB6EqT8" target="_blank">Portal Amunategui, Piso 4, Oficina 415</a>
                        </div>
                    </div>
                    
                    <div class="col-md-3">
                        <div class="contact-info-single">
                            <div class="icon-box"><i class="fa fa-clock-o"></i></div>	
                            <h3>Horario</h3>
                            <label>Lunes - Viernes:</label>
                            <p>09:00 - 18:00</p>
                        </div>
                    </div>
                </div>
                
                <div class="row contact-form">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" id="name" class="form-control" placeholder="Nombre y apellido" />
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" id="email" class="form-control" placeholder="Tú correo electronico" />
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" id="telephone" class="form-control" placeholder="Número de teléfono" />
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea rows="10" id="comments" class="form-control" placeholder="Ingresa tus comentarios"></textarea>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <input type="submit" value="Enviar" class="btn-custom" />
                            </div>
                </div>
            </div>
        </section>
        <section class="ubicacion" id="ubicacion">
            <div class="container">
            
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-title" style={{marginBottom: 0, marginTop: 30}}>
                            <h2>Ubicación</h2>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-xs-12 ubicacion-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.411009136906!2d-71.25403622537517!3d-29.910067525056437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691cbac8b4a2643%3A0x2b438e9b2aaf4942!2sPortal%20Amun%C3%A1tegui!5e0!3m2!1ses-419!2ses!4v1685032482761!5m2!1ses-419!2ses" width="100%" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="siteinfo">
                            <p>Copyright &copy; Vaccin. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
    );
}