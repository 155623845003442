import React from 'react';
import NavBar from './components/navbar/NavBar';
import HomePage from './pages/HomePage/HomePage';

function App() {
  return (
    <>
      <NavBar />
      <HomePage />
    </>
  );
}

export default App;
