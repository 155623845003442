import { MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem } from 'mdb-react-ui-kit';
import { useState } from 'react';

export default function NavBar() {
    const [showNavRight, setShowNavRight] = useState(false);

    return (
    <MDBNavbar expand='lg' light bgColor='light'>
        <MDBContainer fluid>
            <a className="navbar-brand-mobile my-2 mt-lg-0" href="#">
                    <img
                        src="/images/logo.png"
                        className="logo"
                        height="50"
                        alt="Vaccine Logo"
                        loading="lazy"
                    />
            </a>
            <MDBNavbarToggler
                type='button'
                data-target='#navbarRightAlignExample'
                aria-controls='navbarRightAlignExample'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={() => setShowNavRight(!showNavRight)}
                >
                <MDBIcon icon='bars' fa/>
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showNavRight}>
                <a className="navbar-brand my-2 mt-lg-0" href="#">
                    <img
                        src="/images/logo.png"
                        className="logo"
                        height="15"
                        alt="Vaccine Logo"
                        loading="lazy"
                    />
                </a>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#inicio">Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#servicios">Servicios</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#nosotros">Nosotros</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#contacto">Contacto</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#ubicacion">Ubicación</a>
                    </li>
                </ul>
            </MDBCollapse>
        </MDBContainer>
    </MDBNavbar>
    );
}